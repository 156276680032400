<script>
import { eventService } from "../../../helpers/backend/event.service";
export default {
  components: {
  },
  data() {
    return {
      form: {
        id: null,
        event_name: "",
        description: "",
        max_claim: 1,
        total_max_claim: 0,
        start_date: new Date().toISOString(),
        finish_date: new Date().toISOString(),
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      //this.form.media = this.$refs.file.files[0]
      if (!this.form.start_date.includes("T"))
            this.form.start_date += "T00:00:00Z";
      if (!this.form.finish_date.includes("T"))
            this.form.finish_date += "T00:00:00Z";
      this.busy = true;
      eventService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to Insert/Update Customer. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.event_name = "";
      this.form.description = "";
      this.form.max_claim = 0;
      this.form.total_max_claim = 0;
      this.form.start_date = new Date().toISOString();
      this.form.finish_date = new Date().toISOString();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    initUpdate(id) {
      this.reset();
      this.$refs.name.$el.focus();
      this.busy = true;
      eventService.getById(id).then(
        (data) => {
          if (data.event != null) {
            this.form.id = data.event.ID;
            this.form.event_name = data.event.event_name;
            this.form.description = data.event.description;
            this.form.max_claim = data.event.max_claim;
            this.form.total_max_claim = data.event.total_max_claim;
            this.form.start_date = data.event.start_date;
            this.form.finish_date = data.event.finish_date;
          } else {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get event to update. Error : event not found";
          }
          this.busy = false;
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get event to update. Error : " + err;
          this.busy = false;
        }
      );
    },
    fetchData() {
      
    },
    onLoaded(){

    },
    onDecode (result) {
      console.log(result)
    }
  },
};
</script>


<template>
  <div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              
              <b-form-group
                id="input-group-1"
                label="Event Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.event_name"
                  type="text"
                  placeholder="Enter Event Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.description"
                  type="text"
                  placeholder="Enter Event Description"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Max Claim per resident:"
                label-for="input3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.max_claim"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Total Max Claim per event (0 means doesn't have limit):"
                label-for="input4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.total_max_claim"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  id="input-group-5"
                  label="Event Start Date:"
                  label-for="input-5"
                >
                  <b-datepicker
                    id="input-5"
                    v-model="form.start_date"
                    required
                  />
                </b-form-group>
              <b-form-group
                  id="input-group-6"
                  label="Event End Date:"
                  label-for="input-6"
                >
                  <b-datepicker
                    id="input-6"
                    v-model="form.finish_date"
                    required
                  />
                </b-form-group>
              
              <b-button type="submit" variant="primary">{{
                isUpdateState ? "Update" : "Submit"
              }}</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>



